import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import { Container, Row, Col, ListGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapPin, faPhoneAlt} from '@fortawesome/free-solid-svg-icons'

const Footer = ({siteAuthor}) => {

  const data = useStaticQuery(graphql`
    query {
      gwLogoOrange: file(relativePath: { eq: "gw-logo-orange.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      aws: file(relativePath: { eq: "aws_partner_new_logo.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      SME: file(relativePath: { eq: "Smart_SME_Supporter_logo.jpg" }) {
        childImageSharp {
          fixed(height: 150, width: 190) {
            ...GatsbyImageSharpFixed
          }
        }
      },
      ISO: file(relativePath: { eq: "ISO27001_logo.jpg" }) {
        childImageSharp {
          fixed(quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <footer>
      <hr/>
      <Container expand="lg">
        <Row>
          <Col><Img fixed={data.gwLogoOrange.childImageSharp.fixed}/></Col>
          <Col lg="auto" className="mb-5">
            <ListGroup variant="flush">
              <ListGroup.Item><i className="fas fa-map-pin"></i>
                <strong>本社</strong>{`　　　　`}<br className="d-block d-lg-none" />
                〒450-0003{`　`}<br className="d-block d-lg-none" />
                愛知県名古屋市中村区名駅南2-14-19{`　`}<br className="d-block d-lg-none" />
                住友⽣命名古屋ビル21階{`　`}
                <a href="https://goo.gl/maps/yrY5LxQjTXzyqkrV6" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faMapPin} />{` `}MAP
                </a>
              </ListGroup.Item>

              <ListGroup.Item>
                <strong>東京事業所</strong>{`　`}<br className="d-block d-lg-none" />
                〒101-0041{`　`}<br className="d-block d-lg-none" />
                東京都千代⽥区神田須田町1-5{`　`}<br className="d-block d-lg-none" />
                ディアマントビル7階{`　`}
                <a href="https://goo.gl/maps/KkoyjvhPksMyk5Vn8" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faMapPin} />{` `}MAP
                </a>
              </ListGroup.Item>

              <ListGroup.Item>
                <strong>⻘森事業所</strong>{`　`}<br className="d-block d-lg-none" />
                〒030-0861{`　`}<br className="d-block d-lg-none" />
                ⻘森県⻘森市⻑島2-13-1{`　`}<br className="d-block d-lg-none" />
                AQUA⻘森スクエアビル7階{`　`}
                <a href="https://goo.gl/maps/iDLdPpWmnugGiJTu6" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faMapPin} />{` `}MAP
                </a>
              </ListGroup.Item>

              <ListGroup.Item className="text-center text-lg-right mt-5">
                <a className="phoneNumber" href="tel:052-581-2600">
                  <FontAwesomeIcon icon={faPhoneAlt} /> 052-581-2600
                </a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <Container xs='auto' style={{ maxWidth: '850px' }}>
        <Row xs='auto' className="mb-5" style={{ maxWidth: '850px' }}>
          <Col className="shadow-box" style={{ padding: '0rem 1rem' }}>
            <Img fixed={data.aws.childImageSharp.fixed} style={{ margin: '1rem 10rem 0rem 10rem' }} />
            <p className="caption text-muted ">
            ※Amazon Web Service、"Powered by Amazon Web Service"ロゴ、
            [およびかかる資料で使⽤されるその他のAWS商標]は、⽶国その他の諸国における、Amazon.com,Inc.またはその関連会社の商標です。
            </p>
          </Col>
          <Col className="align-items-center text-center justify-content-center shadow-box" style={{ padding: '0rem 1rem' }} >
            <a href="https://smartsme.go.jp/" target="_blank" rel="noopener noreferrer" >
            <Img fixed={data.SME.childImageSharp.fixed} /></a>
          </Col>
          <Col className="align-items-center text-center justify-content-center shadow-box" style={{ padding: '0rem 1rem' }} >
            <a href="http://www.eqaicc.co.jp/intra/intra.action?doc_id=anab" target="_blank" rel="noopener noreferrer" >
            <Img fixed={data.ISO.childImageSharp.fixed} /></a>
            <h3 className="h3">ISO27001：2013<br className="d-none d-lg-block" />登録番号：IA140541</h3>本社・東京事業所での認証
          </Col>
        </Row>
      </Container>
     <Container xs='auto' style={{ maxWidth: '850px' }}>
        <p className="caption ">
          <a href="/privacyPolicy/" target="_blank" rel="noopener noreferrer" >プライバシーポリシー</a>{`　`}
          © {new Date().getFullYear()}{` `}{siteAuthor}
        </p>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  siteAuthor: PropTypes.string
}

Footer.defaultProps = {
  siteAuthor: ``
}

export default Footer
